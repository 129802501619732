
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-message {
  font-size: 24px;
  color: rgb(17, 16, 16);
  background-color: rgba(255, 255, 255, 0.5);
  /* Semi-transparent white background for the message */
  padding: 20px;
  border-radius: 5px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  /* Semi-transparent border */
  border-top: 4px solid #333;
  /* Darker color for the spinner */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  /* Animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container{
  display: flex;
  flex-direction: column;
  min-height: 100%; /* Ensure the container fills the entire viewport height */
}

/* Styles for the coupons header */
.coupons-header {
  height: auto;
  width: 100%;
  padding-bottom: 10px;
  /*background-color: rgb(226 234 250);*/

}

.coupons-body {
  height: auto;
  min-height: 70vh;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* Styles for the select box */
.select-box {
  /* margin-top: 10px; */
  width: 300px;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  appearance: none;
  /* Hide default arrow */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000000" width="18px" height="18px"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

/* Style for the options within the select box */
.select-box option {
  font-size: 16px;
}

/* Styles for the coupon container */
.coupon-container {
  width: 100%;
  /*padding: px;*/
}

/* Styles for each coupon tile */
.coupon-tile {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

/* Styles for the business image */
.business-image {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.business-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  /*border-bottom: 1px solid black;*/
}

.business-image p {
  height: auto;
  object-fit: cover;

}

/* Styles for the business image */
.coupon-image {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.coupon-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  /*border-bottom: 1px solid black;*/
}

/* Styles for the coupon details */
.coupon-details {
  padding: 10px;
  background-color: rgb(255, 255, 255);
}

.coupon-details h3 {
  margin-top: 2px;
  margin-bottom: 5px;
}

.coupon-details p {
  margin: 5px 0;
}

/* Styles for coupon title and valid until */
.coupon-info {
  display: flex;
  justify-content: space-between;
  font-size: medium;
}

.coupon-details .moreinfo {
  margin-top: 5px;
  font-size: medium;
}

.address {
  padding-top: 5px;
  font-size: small;
  overflow: hidden;
  /* Hide overflow */
  text-overflow: ellipsis;
  /* Show ellipsis for truncated text */
  white-space: nowrap;
  /* Prevent wrapping */
  max-width: 90%;
  /* Adjust the maximum width as needed */
}

.load-more-btn {
  margin: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #54628f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.load-more-btn:hover {
  background-color: #0056b3;
}

/* Styles for the footer */
footer {
  background-color: #ededed;
  color: #333;
  padding: 20px;
  text-align: center;
}

/* Modal container */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Close button */
.modal-content button {
  margin-top: 10px;
  padding: 15px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  /* Align the button to the right */
  font-size: large;
}

/* Close button hover effect */
.modal-content button:hover {
  background-color: #0056b3;
}

.modal-content .coupon-info {
  display: flex;
  justify-content: space-between;
  color: rgb(238, 131, 10);
}

.modal-content h2 {
  color: red;
}


/* Responsive styles */
@media (min-width: 600px) {
  .App {
    text-align: left;
    /* Align content to the left on larger screens */
  }

  .App-logo {
    height: 20vmin;
    /* Reduce logo size on larger screens */
  }

  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(2px + 2vmin);
    color: black;
  }

  .coupon-container {
    padding: 0;
    /* Remove left padding on larger screens */
  }

  .coupon-tile {
    margin: 10px;
    /* Reduce margin between coupon tiles */
  }

  .modal-content button {
    padding: 15px 0;
    /* Adjust button padding for better spacing */
  }

  .select-box {
    margin-bottom: 10px;
    width: 30%;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    appearance: none;
    /* Hide default arrow */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000000" width="18px" height="18px"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
  }

  /* Style for the options within the select box */
  .select-box option {
    font-size: 16px;
  }

  /* Styles for the coupons header */
  .coupons-header {
    width: 80%;
    padding-bottom: 10px;

  }

  .coupons-body {
    height: auto;
    min-height: 70vh;
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

  }

  /* Styles for the business image */
  .business-image {
    width: 100%;
    height: 20%;
    align-items: center;
    justify-content: center;
  }

  .business-image img {
    width: 40%;
    height: 20%;
    object-fit: cover;
    /*border-bottom: 1px solid black;*/
  }

  .business-image p {
    height: 20%;
    object-fit: cover;

  }

  /* Styles for the coupon container */
  .coupon-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* Three columns */
    grid-template-rows: minmax(80px, auto);
    /* Three rows */
    gap: 10px;
    /* Gap between grid items */
    overflow: auto;

  }

  /* Styles for each coupon tile */
  .coupon-tile {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .coupon-details .moreinfo {
    padding-top: 15px;
    font-size: medium;

  }

  /* Modal container */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    /* Adjust width as needed */
    max-width: 400px;
    /* Example max-width */
    height: auto;
    /* Let the height adjust based on content */
    max-height: 80%;
    /* Example max-height */
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

}